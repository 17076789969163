import React from 'react';
import { Github, Linkedin, FileText, Calendar } from 'lucide-react';

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-100 to-purple-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">
          Welcome! 👋
        </h1>
        <p className="text-xl mb-6 text-center text-gray-600">
          I'm excited to share my professional journey with you.
        </p>

        <div className="flex justify-center space-x-4 mb-6">
          <a
            href="https://iumanov.me/resume"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex flex-col items-center justify-center px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            <FileText size={18} className="mb-1" />
            Resume
          </a>
          <a
            href="https://calendly.com/yumanovm/personal"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex flex-col items-center justify-center px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
          >
            <Calendar size={18} className="mb-1" />
            Schedule
          </a>
        </div>

        <div className="flex justify-center space-x-4 mb-6">
          <a
            href="https://github.iumanov.me/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-700 hover:text-gray-900 transition-colors"
          >
            <Github size={24} />
          </a>
          <a
            href="https://linkedin.com/in/mikhailyumanov"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-700 hover:text-gray-900 transition-colors"
          >
            <Linkedin size={24} />
          </a>
        </div>
        
        <p className="mt-6 text-center text-green-600 font-semibold">
          #OpenToWork
        </p>
      </div>
    </div>
  );
};

export default App;
